<template>
  <tbody @click="$emit('click')" :class="{selected: selected}">
    <tr class="single">
      <td v-for="column in schemas" :key="column.label" :style="{width: column.width + 'px' || 'auto', maxWidth: column.width + 'px' || 'auto', textAlign: column.align}">
        <TwOverflowTooltip v-if="column.dataType === 'String' && row[column.key]" :content="row[column.key]" />
        <label v-else-if="column.dataType === 'Checkbox' && row[column.key]" class="el-checkbox is-disabled is-checked">
          <span class="el-checkbox__input is-checked">
            <span class="el-checkbox__inner"></span>
            <input type="checkbox" aria-hidden="false" class="el-checkbox__original" value="" disabled>
          </span>
        </label>
      </td>
    </tr>
  </tbody>
</template>

<script>
import TwOverflowTooltip from '@/components/atoms/TwOverflowTooltip';

export default {
  name: 'TwProcessGroupListPreviewGoodsLineCoTable',
  props: {
    selected: Boolean,
    row: Object,
    schemas: Array,
  },
  components: {
    TwOverflowTooltip,
  },
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {

  },
};
</script>

<style lang="scss" scoped>
  tbody:hover {
    background-color: $color_gray_background;

    td.goods_line_table_inner img.close_times {
      opacity: 1;
    }
  }

  tbody.selected {
    background-color: rgba(103, 239, 10, 0.2);
  }

  tbody.is_error {
    background-color: rgba(224, 0, 1, 0.07);;
  }

  tbody.invalid td:not(.goods_line_table_inner) {
    background-color: rgba(224, 0, 1, 0.07);
  }

  th {
    text-align: center;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    word-break: break-word;
    color: #5E5873;
    padding: 4px 5px;
    border: 1px solid $color_gray_300;
    background: $color_dark_blue;
    color: white;
    // font-weight: bold;
  }

  td {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    white-space: nowrap;
    padding: 0;
    border: 1px solid $color_gray_300;
    border-bottom: 0;
    height: 30px;
    vertical-align: middle;
    padding: 0 5px;
    font-size: 12px;
    // white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;
  }

  tr.single td {
    border-bottom: 1px solid $color_gray_400!important;
  }

  td .el-checkbox * {
    cursor: not-allowed;
  }

  td.goods_line_table_inner {
    position: relative;
    padding: 0;
    border-bottom: 1px solid $color_gray_400!important;
    // border-top: 1px solid #666;

    img.close_times {
      position: absolute;
      right: 4px;
      top: 50%;
      margin-top: -10px;
      width: 20px;
      height: 20px;
      cursor: pointer;
      opacity: 0;
    }

    table  {
      table-layout: fixed;
      min-width: 100%;
      border: 0;
      border-collapse: collapse;

      th {
        border-top: 0!important;
        border-bottom: 0;
        padding: 0 5px;
        line-height: 0;
        height: 0;
        overflow: hidden;
        background: transparent;
      }

      td {
        border-top: 0!important;
        border-bottom: 0!important;
        padding: 0 5px;
      }

      tr th:first-child, tr td:first-child {
        border-left: 0!important;
      }

      tr th:last-child, tr td:last-child {
        border-right: 0!important;
      }
    }
  }


</style>
